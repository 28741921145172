<template>
  <title-wrapper title="Strona główna"> </title-wrapper>
</template>
<script>
import TitleWrapper from '../components/TitleWrapper.vue';
export default {
  components: { TitleWrapper },
};
</script>
<style lang="scss" scoped></style>
